import React, { ChangeEvent, useEffect, useState } from 'react'
import { Hidden, useScreenClass, Visible } from 'react-grid-system'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'

import Image from 'next/image'

import { Grid, Switch } from '@material-ui/core'
import { Button, InputText, Select } from 'components/form'
import { Text, View } from 'components/helpers'
import { useAddress } from 'hooks/useAddress'
import { getAllCityByState, getAllState } from 'services/thirdParty'
import { RootState } from 'store/reducers'
import { formatCep } from 'utils/helper/string'

import { Container } from './styles'

const StreetRegister: React.FC = () => {
  const { app } = useSelector((state: RootState) => state)

  const screenClass = useScreenClass()

  const { register, handleSubmit, errors, setValue, trigger } = useForm({
    defaultValues: {
      cep: '',
      street: '',
      number: '',
      neighborhood: '',
      more: '',
      state: '',
      city: '',
      nickname: '',
      isDefault: true
    }
  })

  const {
    addAddress,
    addNewAddress
  } = useAddress()

  const [states, setStates] = useState<any[]>([])
  const [cities, setCities] = useState<any[]>([])

  useEffect(() => {
    // if (user) {
    //   setValue('cep', user.cep)
    //   setValue('nickname', user.nickname)
    //   setValue('street', user.street)
    //   setValue('number', user.number)
    //   setValue('neighborhood', user.neighborhood)
    //   setValue('more', user.more)
    // }

    getAllState()
      .then(({ data }) => {
        setStates(data.map((item: any) => ({ label: item.nome, value: item.sigla })))
        // if (user?.state) {
        //   getAllCityByState(user?.state)
        //     .then(({ data }) => {
        //       setCities(data.map((item: any) => ({ label: item.nome, value: item.nome })))
        //       setValue('state', user?.state)
        //       setValue('city', user?.city)
        //     })
        // }
      })
  }, [])

  const onChangeCep = (e: ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value
    const formatedText = formatCep(text);
    setValue('cep', formatedText);
    if (formatedText.length >= 9) {
      addNewAddress(text)
        .then(async result => {
          if (result) {
            const formatedCep = formatCep(result?.cep || '');
            setValue('cep', formatedCep)
            setValue('street', result?.street)
            setValue('neighborhood', result?.neighborhood)
            // setValue('number', result?.number)
            // setValue('more', result?.more)

            getAllCityByState(result?.state)
              .then(({ data }) => {
                setCities(data.map((item: any) => ({ label: item.nome, value: item.nome })))
                setValue('state', result?.state)
                setValue('city', result?.city)
              })
            
            await trigger();
          }
        })
    }
  }

  const onChangeState = (e: ChangeEvent<HTMLSelectElement>) => {
    const state = e.target.value
    getAllCityByState(state)
      .then(({ data }) => {
        setCities(data.map((item: any) => ({ label: item.nome, value: item.nome })))
        setValue('city', '')
      })
  }

  const onBlur = async (e: ChangeEvent<HTMLInputElement>) => {
    await trigger();
  }

  function hasErrors(errors:any) {
    return Object.keys(errors).some((key) => errors[key]);
  }

  const onSubmit = (data: any) => {
    addAddress(data)
  }

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.6 }}
    >
      <View className='create-address-container'>
        <Visible xs>
          <View
            mt={0.1}
            mb={2}
          >
            <Text
              size={18}
              fontWeight={700}
              color='#EA2A2F'
            >
              Cadastrar endereço
            </Text>
          </View>
        </Visible>

        <Hidden xs>
          <View mb={1}>
            <View justify='center' mt={1} mb={1}>
              <Image
                src='/images/logo-small.svg'
                width={50}
                height={50}
                alt='Farmazon Logo'
                priority
              />
            </View>
          </View>
        </Hidden>
        <form onSubmit={handleSubmit(onSubmit)}>

          {/* <Grid item xs={12}>
            <View mb={1}>
              <InputText
                name='nickname'
                errorMessage={errors.nickname?.message}
                ref={register({ required: 'Digite um apelido.' })}
                placeholder='Salvar como *'
              />
            </View>
          </Grid> */}
          <p className='font-normal mb-2 text-sm'>Digite primeiro o seu CEP</p>
          <Grid item xs={5}>
            <View mb={1}>

              <InputText
                name='cep'
                errorMessage={errors.cep?.message}
                ref={register({
                  required: 'Digite seu cep.',
                  pattern: {
                    value: /^\d{5}-?\d{3}$/,
                    message: 'Digite um cep válido.'
                  }
                })}
                onChange={onChangeCep}
                onBlur={onBlur}
                placeholder='CEP *'
                inputMode='numeric'
              />
            </View>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={8}>
              <View mb={1}>
                <InputText
                  name='street'
                  errorMessage={errors.street?.message}
                  onBlur={onBlur}
                  ref={register({
                    required: 'Digite seu endereço',
                    maxLength: {
                      value: 60,
                      message: 'Tamanho máximo 60 caracteres.'
                    }
                  })}
                  placeholder='Endereço *'
                />
              </View>
            </Grid>
            <Grid item xs={4}>
              <View mb={1}>
                <InputText
                  name='number'
                  errorMessage={errors.number?.message}
                  onBlur={onBlur}
                  ref={register({
                    required: 'Digite número do endereço',
                    valueAsNumber: true
                  })}
                  placeholder='Número *'
                  inputMode='numeric'
                />
              </View>
            </Grid>
          </Grid>

          <View mb={1}>
            <InputText
              name='neighborhood'
              errorMessage={errors.neighborhood?.message}
              onBlur={onBlur}
              ref={register({
                required: 'Digite seu bairro'
              })}
              placeholder='Bairro *'
            />
          </View>
          <View mb={1}>
            <InputText
              name='more'
              errorMessage={errors.more?.message}
              onBlur={onBlur}
              ref={register({
                required: 'Exemplo: Casa ou bloco C ap 102'
              })}
              placeholder='Complemento *'
            />
          </View>

          <Grid container spacing={['xs'].includes(screenClass) ? 1 : 4}>
            <Grid item xs={12} sm={6}>
              <View>
                <Select
                  className='create-address-select'
                  name='state'
                  errorMessage={errors.state?.message}
                  ref={register({
                    required: 'Digite seu estado'
                  })}
                  onChange={onChangeState}
                >
                  <option value=''>Selecione um estado</option>
                  {states.map((item, index) => (<option key={index} value={item.value}>{item.label}</option>))}
                </Select>
              </View>
            </Grid>

            <Grid item xs={12} sm={6}>
              <View>
                <Select
                  name='city'
                  errorMessage={errors.city?.message}
                  ref={register({
                    required: 'Digite sua cidade'
                  })}
                  placeholder='Cidade *'
                  className='create-address-select'
                >
                  <option value=''>Selecione uma cidade</option>
                  {cities.map((item, index) => (<option key={index} value={item.value}>{item.label}</option>))}
                </Select>
              </View>
            </Grid>

            <View mb={1} direction='row' align='center'>
              <Switch name='isDefault' defaultChecked inputRef={register} />

              <Text size={12}>Endereço principal</Text>
            </View>
          </Grid>

          <View mt={2}>
            <Button
              disabled={hasErrors(errors)}
              label='Salvar endereço'
              loading={app.loading}
              background='#52C372'
            />
          </View>
        </form>
      </View>
    </Container>
  )
}

export default StreetRegister
