import { Auth } from "aws-amplify"
import { newVersion } from 'store/reducers'
import { batch } from "react-redux"
import { clearToken } from "services/AuthService"
import { addAddressTemporaryAction } from "store/reducers/addressTemporaryReducer"
import { setScreenAction } from "store/reducers/appReducer"
import { resetCartAction } from "store/reducers/cartReducer"
import { resetUserState } from "store/reducers/userReducer"
import { initializeStore } from "store"

export const logout = () => {
  const store = initializeStore({})

  clearToken()
  localStorage.setItem(`persist:${newVersion}`, '')
  store.dispatch(resetUserState())
  store.dispatch(resetCartAction())
  setTimeout(() => {
    Auth.signOut()
    batch(() => {
      store.dispatch(addAddressTemporaryAction({
        street: 'Rua Almirante Pereira Guimarães',
        lat: -22.9864275,
        lng: -43.2167975
      }))
      store.dispatch(setScreenAction(''))
    })
    location.replace('/')
  }, 100)
}