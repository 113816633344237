
import { ChangeEvent, useState } from 'react'
import { useAlert } from 'react-alert'
import { batch, useDispatch, useSelector } from 'react-redux'

import debounce from 'lodash.debounce'
import { defaultAddressCard, deleteAddress, getAllAddress, postAddress } from 'services/address'
import { checkCartProducts, getProductCategory } from 'services/product'
import { getAddressByCep, getAddressByText, getByLatLng } from 'services/thirdParty'
import { RootState } from 'store/reducers'
import { addAddressTemporaryAction, resetAddressTemporaryAction } from 'store/reducers/addressTemporaryReducer'
import { disableLoadingAction, disableLocationAction, enableLoadingAction, setScreenAction } from 'store/reducers/appReducer'
import { setCartAction } from 'store/reducers/cartReducer'
import { addListAddressesAction, resetAddressAction, setAddressSelectedNullAction, setAddressSelectedAction } from 'store/reducers/userReducer'
import { mutate } from 'swr'

const CATEGORY = 'CATEGORY'

export const useAddress = () => {
  const { user, cart } = useSelector((state: RootState) => state)
  const dispatch = useDispatch()
  const alert = useAlert()
  const [listAddress, setListAddress] = useState([])

  const [addressForDetele, setAddressForDetele] = useState<string>()

  const [showSearchInput, setShowSearchInput] = useState(false)

  const updateAddressForDelete = (id?: string) => {
    return () => {
      setAddressForDetele(id)
    }
  }

  const handleToggleShowSearchInput = () => {
    setShowSearchInput(oldShowSearchInput => !oldShowSearchInput)
  }

  const addAddress = async (data: any) => {
    dispatch(enableLoadingAction())
    // @ts-ignore
    alert.removeAll()
    try {
      const payload = {
        nickname: data.street,
        country: 'Brasil default',
        state: data.state,
        city: data.city,
        neighborhood: data.neighborhood,
        street: data.street,
        number: data.number,
        reference: data.reference || 'sem referência',
        more: data.more || 'sem more',
        cep: data.cep,
        default: data.isDefault
        // latitude: user.latitude,
        // longitude: user.longitude,
      }
      const { data: currentAddress } = await postAddress(payload)
      const { data: Addresses } = await getAllAddress()

      batch(() => {
        dispatch(setAddressSelectedAction(payload.street, currentAddress.pub_id, payload.city))
        dispatch(addListAddressesAction(Addresses.Items))
      })

      mutate('/customers/delivery_address/')
      batch(() => {
        dispatch(disableLocationAction())
        dispatch(resetAddressAction())
        dispatch(setScreenAction(''))
        dispatch(resetAddressTemporaryAction())
      })

      alert.success('Endereço cadastrado com sucesso.')
    } catch (error: any) {
      alert.error(error.response?.data?.message)
    } finally {
      batch(() => {
        dispatch(disableLoadingAction())
        dispatch(disableLocationAction())
      })
    }
  }

  const deleteAddressById = async (id: string) => {
    try {
      // @ts-ignore
      alert.removeAll()
      // exclui o registro do banco/api
      await deleteAddress(id)
    } catch (error: any) {
      // alert.error(error.response?.data?.message ?? 'Erro ao tentar excluir endereço da API')
    }
    try {
      const { data: addresses } = await getAllAddress()
      batch(() => {
        // atualiza lista no obj User da aplicação
        dispatch(addListAddressesAction(addresses.Items))
      })
      const defaultAddress = addresses.Items.find(address => address.default)
      // verifica se tem outro endereço/default 
      batch(() => {
        if (!!defaultAddress) {
          dispatch(setAddressSelectedAction(defaultAddress.street, defaultAddress.pub_id, defaultAddress.city))
        }
        else {
          dispatch(setAddressSelectedNullAction())
        }
      })
    } catch (error: any) {
      // alert.error(error.response?.data?.message ?? 'Erro ao tentar excluir endereço')
    }
    try {
      mutate('/customers/delivery_address/')
      alert.success('Endereço removido com sucesso.')
    } catch (error: any) {
      alert.error(error.response?.data?.message ?? 'Erro ao tentar excluir endereço')
    }
  }

  const setDefaultAddress = async (id: string, address?: any[]) => {
    try {
      // @ts-ignore
      alert.removeAll()
      dispatch(disableLocationAction())

      let deliveryAddresses: any
      if (address) {
        deliveryAddresses = address.map(item => item.pub_id === id ? { ...item, default: true } : { ...item, default: false })
      } else {
        deliveryAddresses = user.delivery_addresses.map(item => item.pub_id === id ? { ...item, default: true } : { ...item, default: false })
      }

      const currentAddress = deliveryAddresses.find((item: any) => item?.default)
      batch(() => {
        dispatch(addListAddressesAction(deliveryAddresses))
        dispatch(setAddressSelectedAction(currentAddress.street, currentAddress.pub_id, currentAddress.city))
      })

      checkCartProducts({ lat: currentAddress.lat, lng: currentAddress.lng, products: cart.items })
        .then(products => {
          dispatch(setCartAction(products))
        })
      getProductCategory({ lat: currentAddress.lat, lng: currentAddress.lng })
        .then(({ data }) => {
          localStorage.setItem(CATEGORY, JSON.stringify(data))
        })

      await defaultAddressCard(id)
      await mutate('/customers/delivery_address/')

      alert.success('Endereço definido com sucesso.')
    } catch (error: any) {
      alert.error(error.response?.data?.message ?? 'Erro ao tentar definir endereço')
    }
  }

  const handleSearchAddress = debounce((e: ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value
    getAddressByText(text)
      .then(result => {
        const listAddress = result.data.features.map((item: any) => ({
          geometry: {
            location: { lat: item.center[1], lng: item.center[0] }
          },
          formatted_address: item.place_name
        }))

        setListAddress(listAddress)
      })
  }, 500)

  const handleRemoveAddress = () => {
    setListAddress([])
  }

  const handleAddLocation = ({ lat: latToSearch, lng: lngToSearch }: {lat: number, lng: number}) =>
    async () => {
      try {
        const { data } = await getByLatLng(latToSearch, lngToSearch)
        const address = data.features[0]

        const lat = latToSearch
        const lng = lngToSearch
        const cep =
        address.context.find((item: any) =>
          item.id.match(/postcode/)
        )?.text || ''
        const street = address.text
        const neighborhood =
        address.context.find((item: any) =>
          item.id.match(/neighborhood/)
        )?.text || ''
        const country =
        address.context.find((item: any) =>
          item.id.match(/country/)
        )?.text || ''
        const state =
        address.context.find((item: any) =>
          item.id.match(/region/)
        )?.text || ''
        const city =
        address.context.find((item: any) =>
          item.id.match(/place/)
        )?.text || ''

        const addressResult = {
          lat,
          lng,
          cep,
          city,
          state,
          street,
          country,
          neighborhood
        }

        batch(() => {
          dispatch(addAddressTemporaryAction(addressResult))
          dispatch(setScreenAction(''))
        })

        return addressResult
      } catch (error) {

      }
    }

  const addNewAddress = async (cep: string) => {
    const { data } = await getAddressByCep(cep.replace('-', ''))
    if (data?.erro) {
      alert.error('Cep não encontrado.')
    } else {
      const payload = {
        nickname: '',
        street: data.logradouro,
        number: '',
        more: data.complemento,
        city: data.localidade,
        state: data.uf,
        neighborhood: data.bairro,
        cep: cep
      }
      return payload
    }
  }

  return {
    addressForDetele,
    updateAddressForDelete,
    addNewAddress,
    addAddress,
    listAddress,
    deleteAddressById,
    setDefaultAddress,
    showSearchInput,
    handleSearchAddress,
    handleRemoveAddress,
    handleAddLocation,
    handleToggleShowSearchInput
  }
}
