import { AnyAction } from 'redux'

const ENABLE_LOADING = 'userReducer/ENABLE_LOADING'
const DISABLE_LOADING = 'userReducer/DISABLE_LOADING'
const SET_MESSAGE = 'userReducer/SET_MESSAGE'
const SET_CEP = 'userReducer/SET_CEP'
const ADD_ADDRESS = 'userReducer/ADD_ADDRESS'
const ADD_PROFILE = 'userReducer/ADD_PROFILE'
const ADD_USERNAME = 'userReducer/ADD_USERNAME'
const SET_LATITUDE = 'userReducer/SET_LATITUDE'
const CLEAN_ADDRESS = 'userReducer/CLEAN_ADDRESS'
const SET_ADDRESS = 'userReducer/SET_ADDRESS'
const CLEAN_ADDRESS_SELECTED = 'userReducer/CLEAN_ADDRESS_SELECTED'
const SET_ON_WHITE = 'userReducer/SET_ON_WHITE'
const SET_SETUP = 'userReducer/SET_SETUP'
const SET_USER_CONFIRMED = 'userReducer/SET_USER_CONFIRMED'
const SET_CLEAN_PASSWORD = 'userReducer/SET_CLEAN_PASSWORD'
const ADD_LIST_ADDRESS = 'userReducer/ADD_LIST_ADDRESS'
const CLEAN_USER = 'userReducer/CLEAN_USER'
const ADD_NAME_EMAIL = 'userReducer/ADD_NAME_EMAIL'
const ADD_CARDS = 'userReducer/ADD_CARDS'
const SET_DELIVERY_FEE = 'userReducer/SET_DELIVERY_FEE'
const ADD_NEAR_STORE = 'userReducer/ADD_NEAR_STORE'
const CLEAN = 'userReducer/CLEAN'
const ADD_CPF = 'userReducer/ADD_CPF'

export interface AddressProps {
  nickname: string
  street: string
  number: string
  more: string
  city: string
  state: string
  cep: string
  neighborhood: string
  latitude: number
  longitude: number
}

export interface ProfileProps {
  username: string
  password: string
  name: string
  cpf: string
  email: string
  delivery_addresses: any[]
  address_selected?: {
    name: string
    pub_id: string
    city: string
  } | null
  onWhiteList?: boolean
  setup: any
  birthdate: string
  userConfirmed: boolean
  cards: Array<{
    mask_number: string
    default: boolean
    card_token: string
    pub_id: string
    branch: string
  }>
  deliveryFee: number
  nearByStore: any[]
  userRole: string[]
}

export interface StateUserProps extends AddressProps, ProfileProps {}

const stateInit = {
  username: '',
  password: '',
  name: '',
  cpf: '',
  email: '',
  birthdate: '',
  userConfirmed: false,
  delivery_addresses: [],
  address_selected: null,
  nickname: '',
  street: '',
  number: '',
  more: '',
  city: '',
  state: '',
  cep: '',
  neighborhood: '',
  country: 'Brasil',
  latitude: 0,
  longitude: 0,
  onWhiteList: false,
  setup: {},
  cards: [],
  deliveryFee: 0,
  nearByStore: [],
  userRole: []
}

export const userReducer = (state: StateUserProps = stateInit, { type, payload }: AnyAction) => {
  switch (type) {
    case ADD_NEAR_STORE:
      return { ...state, nearByStore: payload }
    case SET_DELIVERY_FEE:
      return { ...state, deliveryFee: payload }
    case ADD_CARDS:
      return { ...state, cards: payload }
    case ADD_CPF:
      return { ...state, cpf: payload }
    case ADD_NAME_EMAIL:
      return { ...state, name: payload.name, email: payload.email }
    case CLEAN_USER:
      return stateInit
    case ADD_LIST_ADDRESS:
      return { ...state, delivery_addresses: payload }
    case SET_SETUP:
      return { ...state, setup: payload }
    case SET_CLEAN_PASSWORD:
      return { ...state, password: '' }
    case SET_USER_CONFIRMED:
      return { ...state, userConfirmed: payload }
    case SET_ON_WHITE:
      return { ...state, onWhiteList: payload }
    case SET_ADDRESS:
      return {
        ...state,
        address_selected: {
          name: payload.name,
          pub_id: payload.id,
          city: payload.city
        }
      }
    case CLEAN_ADDRESS_SELECTED:
      return {
        ...state,
        address_selected: null
      }
    case ADD_USERNAME:
      return { ...state, username: payload }
    case SET_MESSAGE:
      return { ...state, messageError: payload }
    case ENABLE_LOADING:
      return { ...state, loading: false }
    case DISABLE_LOADING:
      return { ...state, loading: true }
    case SET_CEP:
      return { ...state, zipcode: payload }
    case SET_LATITUDE:
      return { ...state, latitude: payload.latitude, longitude: payload.longitude }
    case CLEAN_ADDRESS:
      return {
        ...state,
        nickname: '',
        street: '',
        number: '',
        more: '',
        city: '',
        state: '',
        neighborhood: '',
        cep: '',
        latitude: 0,
        longitude: 0
      }
    case ADD_ADDRESS:
      return {
        ...state,
        nickname: payload.nickname,
        street: payload.street,
        number: payload.number,
        more: payload.more,
        city: payload.city,
        state: payload.state,
        neighborhood: payload.neighborhood,
        cep: payload.cep,
        latitude: payload.latitude,
        longitude: payload.longitude
      }
    case ADD_PROFILE:
      return {
        ...state,
        name: payload.name,
        cpf: payload.cpf,
        email: payload.email,
        username: payload.username,
        birthdate: payload.birthdate,
        password: payload.password,
        userRole: payload.userRole
      }
    case CLEAN:
      return stateInit
    default:
      return state
  }
}

export const addNearByStoreAction = (payload: any) =>
  ({ type: ADD_NEAR_STORE, payload })

export const setDeliveryFeeAction = (payload: number) =>
  ({ type: SET_DELIVERY_FEE, payload })

export const addUserCardsAction = (payload: any) =>
  ({ type: ADD_CARDS, payload })

export const addNameEmailAction = (payload: any) =>
  ({ type: ADD_NAME_EMAIL, payload })

export const cleanUserAction = () =>
  ({ type: CLEAN_USER })

export const addListAddressesAction = (payload: any[]) =>
  ({ type: ADD_LIST_ADDRESS, payload })

export const cleanPasswordAction = () =>
  ({ type: SET_CLEAN_PASSWORD })

export const setUserConfirmedAction = (payload: boolean) =>
  ({ type: SET_USER_CONFIRMED, payload })

export const setSetupAction = (payload: any) =>
  ({ type: SET_SETUP, payload })

export const setOnWhiteListAction = (payload: boolean) =>
  ({ type: SET_ON_WHITE, payload })

export const setAddressSelectedNullAction = () =>
  ({ type: CLEAN_ADDRESS_SELECTED })

export const setAddressSelectedAction = (name: string, id: string, city: string) =>
  ({ type: SET_ADDRESS, payload: { name, id, city } })

export const resetAddressAction = () =>
  ({ type: CLEAN_ADDRESS })

export const addProfileAction = (payload: Partial<ProfileProps>) =>
  ({ type: ADD_PROFILE, payload })

export const setLatitudeAction = (payload: any) => ({ type: SET_LATITUDE, payload })
export const addUserNameAction = (payload: string) => ({ type: ADD_USERNAME, payload })
export const setMessageUserAction = (message: string) => ({ type: SET_MESSAGE, payload: message })
export const enableUserAction = () => ({ type: ENABLE_LOADING })
export const disableUserAction = () => ({ type: DISABLE_LOADING })
export const setCEPAction = (zipcode: string) => ({ type: SET_CEP, payload: zipcode })
export const addAddressAction = (payload: AddressProps) => ({ type: ADD_ADDRESS, payload })
export const resetUserState = () => ({ type: CLEAN })
export const updateCPF = (payload: string) => ({ type: ADD_CPF, payload })
